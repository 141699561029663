import React from "react";

export default function Contact() {
  return (
    <div className="c-content">
      <div className="c-content-header">
        <h4>Contact</h4>
        <p>Overview</p>
      </div>
    </div>
  );
}
