import React from "react";

export default function Chart() {
  return (
    <div className="c-content">
      <div className="c-content-header">
        <h4>Chart</h4>
        <p>Overview</p>
      </div>
    </div>
  );
}
